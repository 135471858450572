import React from 'react'
import Images from '../../../assets/Images'
import { useNavigate } from 'react-router'
import atoms from '../../atoms'
import { Context } from '../../../Context'
import { numberWithCommas } from '../../../constants/utils'
import { UseDispatch, useDispatch, useSelector } from 'react-redux'
import { setPageLimit } from '../../../features/productList/pageCount/pageCount'
import { setPreviousListLocation } from '../../../features/productList/listLocation/listLocation'
import { RootState } from '../../../store/store'
import constant from '../../../constants/constant'
import { TRUE } from 'sass'
const { CoverImage } = Images
const { AvailableBadge, CustomImages } = atoms
const Index = ({ productData }: any) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const reduxPageCount = useSelector((state: RootState) => state?.rootReducer?.pageCount);
    const reduxLimitPerPage = useSelector((state: RootState) => state?.rootReducer.pageCount.limit)
    const reduxProductListLocation = useSelector((state: RootState) => state?.rootReducer?.listLocation);


    const handleClick = () => {
        navigate(`/product/details/${productData?.id}`)
        dispatch(setPageLimit(reduxLimitPerPage * reduxPageCount?.pageCount))
        dispatch(setPreviousListLocation(reduxProductListLocation?.listLocation))
    }

    const badgeTitle = productData?.available_at_store ? "Available At Store"
        : productData?.available_at_other_store ? 'Available At Branch'
            : `Available in:${productData?.transit_days}`

    const badgeStyle = productData?.available_at_store ? "available"
        : productData?.available_at_other_store ? 'otherunavailable'
            : 'unavailable'

    const notShowBadge: boolean = !productData?.available_at_store ?
        (productData?.available_at_other_store ? true :
        productData?.transit_days !== constant.Product_Transit_Days_Undefined) : true;

        
    return (
        <div className="col-6 col-md-4 mt-2">
            <div className='product-card position-relative' onClick={() => handleClick()}>
                {notShowBadge && <div className="product-card-badge">
                    <AvailableBadge title={badgeTitle} style={badgeStyle} />
                </div>}
                <CustomImages className='product-img' imageHeight={window.innerWidth < 876 ? '60px' : '200px'} src={productData?.images[0] || CoverImage} />
                {/* <div className='product-img' style={{ backgroundImage: `url(${productData?.images[0] || CoverImage})` }}>
                </div> */}
                <div className={`${window.innerWidth < 876 && 'row'}`}>
                    <div className='col-md-12 col-7 d-md-flex flex-wrap justify-content-between align-items-md-center mt-md-4 mt-2'>
                        <h2 className="fs-md-16 fs-10 mb-0 fw-700">{productData?.brand?.brand_name}</h2>
                        <h2 className="fs-md-14 fs-10 mb-0 mt-2 mt-md-0 fw-600 d-none d-md-block ">₹{numberWithCommas(parseFloat(productData?.product_srp?.toFixed(2)))}/-</h2>
                        <h2 className="fs-md-14 fs-10 mb-0 mt-2 mt-md-0 fw-600 d-md-none">{productData?.modal_number}</h2>

                    </div>
                    <h2 className='col-md-12 col-5 fs-12 fw-600 px-0 mx-0 my-2 d-md-none'> ₹{numberWithCommas(parseFloat(productData?.product_srp?.toFixed(2)))}/-</h2>

                    <div className='col-md-12 col-5 d-md-flex flex-wrap justify-content-between align-items-md-center'>
                        {!productData?.is_contact_lens && <h2 className=' fs-12 fw-500 my-2'>{window.innerWidth > 768 && "Model No:"}<span> {productData?.modal_number} </span></h2>}
                        {productData?.is_contact_lens && <h2 className='fs-12 fw-500 my-2'>Lens Name: <span>{productData?.lens_name}</span></h2>}
                        {productData?.is_contact_lens && <h2 className='fs-12 fw-500 my-2'>Color: <span>{productData?.color?.color_name}</span></h2>}

                        {/* {!productData?.available_at_store && <h2 className=' d-sm-none d-md-block fs-12 fw-500 my-xl-2 fw-700 arriving-date-tag' >{"Arriving in:"}<span > {productData?.transit_days} </span></h2>} */}
                    </div>

                    <div className='d-none d-lg-flex justify-content-between align-items-center'>
                        <h2 className={`mb-0 fs-12 fw-500 ${productData?.is_contact_lens ? 'invisible' : ''}`}>Size/Power: <span>{productData?.glass_size}MM</span></h2>
                        <h2 className={`mb-0 fs-12 fw-500 mt-md-0 mt-2 ${productData?.is_contact_lens ? 'invisible' : ''}`}>Color: <span>{productData?.brand_color_code}</span></h2>
                    </div>
                    <div className='d-md-none'>
                        <h2 className=' fs-12 fw-500 my-2'>{"Available in:"}<span> {productData?.transit_days} </span></h2>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Index