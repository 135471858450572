import { createSlice } from "@reduxjs/toolkit";
import constant from "../../../constants/constant";

const initialState = {
  search: "",
  searchType: constant.Product_List_Search_Option[1].value,
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload;
    },
    setResetAllSearch: (state) => {
      state.search = initialState.search;
      state.searchType = initialState.searchType;
    },
  },
});

export const { setSearch, setSearchType, setResetAllSearch } =
  searchSlice.actions;
export default searchSlice.reducer;
