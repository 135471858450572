import { useContext } from "react"
import { Context } from "../Context"
import service from "../api/services"
import { BASE_URL, Pt_Lense } from "./defaultValues"
import constant from "./constant"

export const getDropdownValue = (constant: any, ind: any) => {
    // generates data for dropdown prefilled 
    let temp: any = {
        "value": 0,
        "label": '',
    }
    constant.map((item: any) => {
        if (item.value === ind) {
            temp.value = item.value
            temp.label = item.label
        }
    })
    return temp
}
export const getUniqueObj = (data: any, id: string, compareId: string) => {
    let tempObj: any
    data && data.map((item: any) => {
        if (item[id] === compareId) {
            tempObj = item
        }
    })
    return tempObj
}
export const getDropdown = (response: [], name: string) => {
    // generate dropdown object from response object 
    let dropdown: any = []
    response && response.map((value: any) => {
        dropdown.push({
            "key": value.id,
            "value": value.id,
            "label": value[name],
        }
        )
    })
    return dropdown
}

export const handleNumber = (e: any) => {
    // validate number 
    const inputValue = e.target.value;
    const digitsOnly = inputValue.replace(/\D/g, '');
    if (digitsOnly.length <= 10) {
        return digitsOnly
    }
}

export const checkPermissionsInArray = (code: string) => {
    // check for permissions in Api for user
    const [userPermission, setUserPermission] = useContext<string[]>(Context);
    return userPermission?.includes(code)
};

export const findConstantLabel = (constant: { label: string, value: string | number }[], value: string | number) => {
    const name: any = constant.find((item: any) => item.value === value);
    return name ? name.label : 'Unknown';
}

export const numberWithCommas = (number: number) => {
    return Number(number).toLocaleString('en-IN'); // Use 'en-IN' for the Indian numbering system
};

export const convertToCommaFormat = (value: string | number): string => {
    return numberWithCommas(Number(Number(value)?.toFixed(2))) || ''
}

export const generatePdf = async (id: string, share?: boolean) => {
    try {
        const response = await service.SalesCart.sharePdf(id, { share: true })
        // if (response.status === 200) {
        //     let path = response.data.data.path
        //     let fullPath = `${BASE_URL}${path}`
        //     let cleanPAth = fullPath.replace(/api\/\.\//, '')
        //     let fileName = cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1)
        //     // window.open(`${cleanPAth}`, '_blank')

        //     setTimeout(() => {
        //         fetch(`${cleanPAth}`).then((response) => {
        //             response.blob().then((blob) => {

        //                 // Creating new object of PDF file
        //                 const fileURL =
        //                     window.URL.createObjectURL(blob);

        //                 // Setting various property values
        //                 let alink = document.createElement("a");
        //                 alink.href = fileURL;
        //                 alink.download = `${fileName}`;
        //                 alink.click();
        //             });
        //         });
        //     }, 2000);

        // }
    } catch (error) {
        console.log('error', error)
    }
}

export const sumTwoNumbers = (number1: number, number2: number) => {
    // ======= sum of two numbers
    let n1 = !Number.isNaN(number1) ? number1 : 0
    let n2 = !Number.isNaN(number2) ? number2 : 0
    // console.log('number1', n1, n2)
    let n3 = Number(n1) + Number(n2)
    // console.log('number===', Number(n3.toFixed(3)))
    return Number(n3.toFixed(3));
}

export const isValidProductType = (productType: any) => {
    const validTypes = Pt_Lense
    return validTypes.includes(productType);
};

export const handleFileDownload = async (response: any) => {
    let path = response
    let fullPath = `${BASE_URL}${path}`
    let cleanPAth = fullPath.replace(/api\/\.\//, '')
    let fileName = cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1)
    // window.open(`${cleanPAth}`, '_blank')

    setTimeout(() => {
        fetch(`${cleanPAth}`).then((response) => {
            response.blob().then((blob) => {

                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);

                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = `${fileName}`;
                alink.click();
            });
        });
    }, 2000);

}

export const handleDecimalInputChange = (handleInputChange: (key: string, value: number) => void, value: number) => {
    const stringValue = value.toString();
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(stringValue) || stringValue === '') {
        handleInputChange("amount", parseFloat(stringValue));
    }
}

export const renderOptions = (
    options: any[],
    selectedValue: any,
    property: string,
    handleClick: (productId: any) => void,
    isPeriod?: boolean
) => {
    return options?.map((option: any, index: number) => {
        const isSelected = selectedValue === option[property];
        return (
            <p
                className={`glass-div cursor-pointer fw-700 fs-md-18 fs-12 my-auto ${isSelected ? 'glass-div-selected' : ''
                    }`}
                key={`glass${index}`}
                onClick={() => handleClick(option.product_id)}
            >
                {isPeriod ? getDropdownValue(constant.PERIOD_USE, option[property])?.label : option[property]}
            </p>
        );
    });
};
export const calculateEyeConditions = (tempEyeDetail: any) => {
    const {
        r_dv_sph,
        r_dv_cyl,
        r_dv_axis,
        r_dv_pd,
        r_add,
        r_nv_sph,
        r_nv_cyl,
        r_nv_axis,
        r_nv_pd,
        l_dv_sph,
        l_dv_cyl,
        l_dv_axis,
        l_dv_pd,
        l_add,
        l_nv_sph,
        l_nv_cyl,
        l_nv_axis,
        l_nv_pd,
    } = tempEyeDetail;

    const isRightEmpty = Boolean(
        r_dv_sph || r_dv_cyl || r_dv_axis || r_dv_pd || r_add ||
        r_nv_sph || r_nv_cyl || r_nv_axis || r_nv_pd
    );

    const isLeftEmpty = Boolean(
        l_dv_sph || l_dv_cyl || l_dv_axis || l_dv_pd || l_add ||
        l_nv_sph || l_nv_cyl || l_nv_axis || l_nv_pd
    );

    const isBothEqual =
        r_dv_sph === l_dv_sph &&
        r_dv_cyl === l_dv_cyl &&
        r_dv_axis === l_dv_axis &&
        r_dv_pd === l_dv_pd &&
        r_add === l_add &&
        r_nv_sph === l_nv_sph &&
        r_nv_cyl === l_nv_cyl &&
        r_nv_axis === l_nv_axis &&
        r_nv_pd === l_nv_pd;

    return { isRightEmpty, isLeftEmpty, isBothEqual };
};
export const determineEyeType = (tempEyeDetail: any) => {
    const { isRightEmpty, isLeftEmpty, isBothEqual } = calculateEyeConditions(tempEyeDetail);

    if (isBothEqual) {
        return { label: 'Both Eyes', value: constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE };
    } else if (isRightEmpty && !isLeftEmpty) {
        return { label: 'Right Eyes', value: constant.EYE_TYPE_CONTACT_LENS.RIGHT_EYE };
    } else if (!isRightEmpty && isLeftEmpty) {
        return { label: 'Left Eyes', value: constant.EYE_TYPE_CONTACT_LENS.LEFT_EYE };
    } else if (!isRightEmpty && !isLeftEmpty) {
        return { label: 'Both Eyes', value: constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE };
    } else if (isRightEmpty && isLeftEmpty) {
        return { label: 'Left Eyes', value: constant.EYE_TYPE_CONTACT_LENS.LEFT_EYE };
    }
};