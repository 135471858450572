import React, { useState, useEffect, useContext } from "react";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import organisms from "../../../components/organisms";
import molecules from "../../../components/molecules";
import { ShowActiveTab } from "../../../Context";
import { useToasts } from "react-toast-notifications";
import constant from "../../../constants/constant";
import service from "../../../api/services";
import { getDropdown } from "../../../constants/utils";

const { InternalTransfer } = organisms;
const { TopBar } = molecules;

const Index = () => {
    const { setActiveTab }: any = useContext(ShowActiveTab);
    const [barcodeData, setBarcodeData] = useState<any>();
    const [storeList, setStoreList] = useState();
    const [searchValue, setSearchValue] = useState<string>("");
    const [updateData, setUpdateData] = useState<any>({
        store: "",
        conditionRemark: "",
    });
    const { addToast } = useToasts();

    const getStoreList = async () => {
        try {
            const response = await service.transferRequestService.storeList();
            if (response.status === 200 || response.status === 201) {
                setStoreList(getDropdown(response?.data?.data, "store_name"));
            }
        } catch (error: any) {
            console.log("error", error);
            addToast(error?.response?.data?.message, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    };

    const handleBarcodeSearch = async (search: string) => {
        try {
            const response = await service.Audit.barcodeSearchApi({ search: search });
            if (response.status === 200) {
                setSearchValue(search);
                getStoreList();
                setBarcodeData(response?.data?.data);
                setUpdateData({
                    ...updateData,
                    item_condition: {},
                    conditionRemark: "",
                });
                // setStoreList(getDropdown(constant?.INVENTORY_ITEM_CONDITION, response?.data?.data?.audit_trails[0]?.new_audit_status))
            }
        } catch (error: any) {
            console.log("error", error);
            addToast(error?.response?.data?.message, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    };

    const handleSubmit = async (id: string) => {
        const payload = {
            destination_store_id: updateData?.store?.value || '',
            inventory_item_id: id,
        };
        try {
            const response = await service.transferRequestService.submit(payload);
            if (response.status === 200) {
                // do not remove until card 792 is done
                // handleBarcodeSearch(searchValue);
                addToast(response?.data?.message, {
                    appearance: "success",
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error: any) {
            console.log("error", error);
            addToast(error?.response?.data?.message, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    };

    const handleChange = (name: string, value: any) => {
        setUpdateData({ ...updateData, [name]: value });
    };

    useEffect(() => {
        setActiveTab("InternalTransfer");
    }, []);
    return (
        <Wrapper>
            <div className="">
                <div className="row align-items-center">
                    <TopBar heading={"Internal Transfer"} />
                </div>
                <InternalTransfer
                    handleBarcodeSearch={(search: string) => handleBarcodeSearch(search)}
                    updateItemCondition={handleSubmit}
                    barcodeData={barcodeData}
                    handleChange={handleChange}
                    updateData={updateData}
                    inventoryItemCondition={storeList}
                />
            </div>
        </Wrapper>
    );
};

export default Index;
