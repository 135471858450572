import PopUpModal from './PopUpModal'
import CreateModal from './CreateModal'
import CreateSideModal from './createSideModal'
import SearchTab from './SearchTab'
import CheckboxDropdown from './CheckboxDropdown'
import CarouselComp from './Carousel'
import TopBar from './TopBar/TopBar'
import ProductCard from './ProductCard'
import Filter from './Filter'
import SalesCard from './SalesCard'
import BillingSummary from './BillingSummary'
import CheckoutSummary from './CheckoutSummary'
import PriceRange from './PriceRange'
import ConfirmationModal from './ConfirmationModal'
import CheckoutCard from './Checkout'
import AuditProduct from './AuditProduct'
import SalesReturnCard from './SalesReturn/SalesReturnCard'
import SalesReturnPopup from './SalesReturn/SalesReturnPopup'
import AddInfoSearch from  "./AddInfoSearch"
import InternalProductDetail from "./InternalProductDetail"

const molecules = {
    PopUpModal,
    CreateModal,
    CreateSideModal,
    SearchTab,
    CheckboxDropdown,
    CarouselComp,
    TopBar,
    ProductCard,
    Filter,
    SalesCard,
    BillingSummary,
    CheckoutSummary,
    PriceRange,
    ConfirmationModal,
    CheckoutCard,
    AuditProduct,
    SalesReturnCard,
    SalesReturnPopup,
    AddInfoSearch,
    InternalProductDetail
}
export default molecules