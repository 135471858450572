import React, { useState, useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import atoms from '../../atoms'
import Images from '../../../assets/Images'
import './Header.scss'
import { TbLogout } from "react-icons/tb";
import { Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader } from 'reactstrap'
import service from '../../../api/services'
import { useToasts } from 'react-toast-notifications'
import { BadgesCount, ShowActiveTab, StorePopup } from '../../../Context'
import { checkPermissionsInArray } from '../../../constants/utils'
import { AllPermissions } from '../../../constants/permission'
import { MdStorefront } from 'react-icons/md'
import LoginImage from '../../../assets/Images/specx_logo.jpg'
import { RxCross2 } from 'react-icons/rx'
import { CiUser } from "react-icons/ci";
import { BsCart3 } from "react-icons/bs";
import { Tooltip } from 'react-tooltip'
import { userLogout } from '../../../features/logout/index'
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
const { Image, Badge } = atoms
const { Logo, User } = Images


interface HeaderProps {
  storeList: { value: string; label: string }[];
}

const Header: React.FC<HeaderProps> = ({ storeList }) => {
  const dispatch: AppDispatch = useDispatch();
  const [toggleDropdown, setToggleDropdown] = useState(false)
  // const [toggleSecoundDropdown, setToggleDropdown] = useState(false)
  const [cartPopup, setCartPopup] = useState(false)
  const [notificationCount, setNotificationCount] = useContext<any>(BadgesCount);
  const { setIsShow } = useContext<any>(StorePopup);
  const sales_count: any = localStorage?.getItem("sales_count");
  const authDetail: any = localStorage?.getItem("auth_details");
  const Auth_details = localStorage?.getItem("auth_details") ? JSON.parse(authDetail) : {}
  const token = Auth_details?.access_token
  const navigate = useNavigate()
  const { addToast } = useToasts();
  const { activeTab }: any = useContext(ShowActiveTab)
  const logout = async () => {
    try {
      if (sales_count > 0) {
        setCartPopup(true)
      } else {
        let response = await service.auth.logout(token)
        if (response.status === 200) {
          dispatch(userLogout())
          addToast(response?.data?.message, {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
          localStorage.clear();
          navigate("/login");
        }
      }
    } catch (error: any) {
      console.log(error)
      addToast(error.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }
  return (
    <>
      <div className='header-main d-flex justify-content-center'>
        <nav className="navbar navbar-expand-lg header-nav" style={{ width: "1440px" }}>
          <div className='d-flex gap-1 align-items-center'>
            {/* <div className="container-fluid  px-md-0 justify-content-between"> */}
            <button
              className="navbar-toggler shadow-none"
              type="button"
              data-bs-toggle="collapse"
              // data-bs-toggle="offcanvas"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fs-10" />
            </button>

            <div className="navbar-brand cursor-pointer nav-logo-width" onClick={() => navigate('/dashboard')}>
              {/* <Image src={Logo} alt="" /> */}
              <Image src={LoginImage} alt="" className='img-fluid logo' />
            </div>
          </div>

          {/* Started User Profile */}

          <div className="col-md-6 col-lg-3 d-flex justify-content-end nav-profile-div gap-1">
            {
              //  checkPermissionsInArray(AllPermissions.Sales_Cart_Tab) && 
              <div className="nav-item my-auto mx-md-3">
                <div className={`nav-link p-0 style-class position-relative cursor-pointer ${(activeTab == 'salesCart' && !(location?.pathname?.split('/')[1] == 'pending')) ? "active" : ""}`}
                  onClick={() => navigate("/sales-cart")}
                  style={{
                    border: '1px solid #a9a9a9',
                    borderRadius: '50%',
                  }}
                >
                  <div className="sales-cart-icon"><BsCart3 /></div>
                  <Badge count={notificationCount?.sales_cart_count} />
                </div>
              </div>}

            <div className="d-block">
              <div className="d-flex align-items-center ">
                <div className='d-none d-md-block text-end color-black fs-14 fw-500'>
                  {/* <div className='m-auto text-capitalize'>
                    {localStorage.getItem("store_name")}
                  </div> */}
                  <div className='m-auto'>
                    {Auth_details?.username}
                  </div>
                  {/* <div className='m-auto d-flex justify-content-start text-truncate' style={{ maxWidth: "150px" }}>
                    {localStorage.getItem("store_location")}
                  </div> */}
                  {
                    localStorage.getItem("store_location") !== null ?
                      <div className='text-truncate' style={{ maxWidth: "171px" }}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={localStorage.getItem("store_location")}>
                        {/* Get stored store_address value from localStorage to show in header */}
                        {localStorage.getItem("store_location")}
                        <Tooltip id="my-tooltip" place='bottom-start' variant='dark' />
                      </div>
                      : ""
                  }
                </div>
                <Dropdown group isOpen={toggleDropdown} size="lg"
                  toggle={() => setToggleDropdown(!toggleDropdown)}>
                  <DropdownToggle className='dropdown-button-hide cursor-pointer p-2'>
                    {/* <Image src={User} /> */}
                    <div className={`nav-link p-0 style-class position-relative cursor-pointer`}
                      style={{
                        border: '1px solid #a9a9a9',
                        borderRadius: '50%',
                      }}
                    >
                      <div className='nav-user-icon'><CiUser /></div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    {
                      storeList.length > 1 && (
                        <div className='p-0 d-flex header-dropdown align-items-center cursor-pointer gap-1 ps-3'
                          onClick={() => setIsShow(true)}
                        >
                          <MdStorefront />
                          <span className=' p-2'
                          >Select Store</span>
                        </div>
                      )
                    }
                    <div className='p-0 d-flex header-dropdown align-items-center cursor-pointer gap-1 ps-3'
                      onClick={() => logout()}
                    >
                      <TbLogout />
                      <span className=' p-2'
                      >Logout</span>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="col-lg-7 collapse navbar-collapse nav-tab-order d-lg-flex justify-content-center nav-list-scroll" id="navbarSupportedContent" style={{ zIndex: "10040" }}>
            <ul className="navbar-nav mb-2 mb-lg-0 gap-lg-3 gap-1">
              {/* <li className="nav-item">
                  <NavLink className=" nav-link p-0 style-class " to="/dashboard">
                    Dashboard
                  </NavLink>
                </li> */}
              {
                //  checkPermissionsInArray(AllPermissions.Product_Tab) &&
                <li className="nav-item">
                  <NavLink className={`nav-link p-0 style-class ${activeTab === "productList" ? "active" : ''}`}
                    to="/product/list"
                    data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => navigate("/product/list")}>
                    Products
                  </NavLink>
                </li>}
              {/* <li className="nav-item">
                  <NavLink className=" nav-link p-0 style-class" to="/inventory-audit">
                    Inventory Audit
                  </NavLink>
                </li> */}
              {/* <li className="nav-item">
                  <NavLink className={`nav-link p-0 style-class position-relative `} to="/sales-cart">
                    Sales Cart
                    <Badge count={notificationCount?.sales_cart_count} />
                  </NavLink>
                </li> */}
              {checkPermissionsInArray(AllPermissions.Sales_Tab) &&
                <li className="nav-item cursor-pointer">
                  <div className="dropdown">
                    <div className={`nav-link p-0 style-class ${(activeTab == 'pending' || activeTab == 'deliver' || (location?.pathname?.split('/')[1] == 'pending')) ? 'active' : ''}`} id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Sales
                      <Badge count={notificationCount?.pending_order_count} className='translate' />
                    </div>
                    {
                      (checkPermissionsInArray(AllPermissions.Pending_Order_Tab) || checkPermissionsInArray(AllPermissions.Sales_History_Tab) || checkPermissionsInArray(AllPermissions.Cancelled_Order_Tab) || checkPermissionsInArray(AllPermissions.Sales_Return_Tab) || checkPermissionsInArray(AllPermissions.Sales_Return_History_Tab))
                      &&
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {
                          checkPermissionsInArray(AllPermissions.Pending_Order_Tab) &&

                          <NavLink
                            className={`nav-link p-0 style-class position-relative ${(activeTab == 'pending' || window?.location?.search === "?pendingOrder") ? "active" : ''}`}
                            to="/pending-receipt"
                            data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => navigate("/pending-receipt")}>
                            Pending Orders
                            <Badge count={notificationCount?.pending_order_count} className='translate-align' />
                          </NavLink>}
                        {checkPermissionsInArray(AllPermissions.Sales_History_Tab) && <NavLink
                          className={`nav-link p-0 style-class ${(activeTab == 'deliver' || window?.location?.search === "?delivered") ? 'active' : ''}`}
                          to="/delivered"
                          data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                          data-bs-target="#navbarSupportedContent"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                          onClick={() => navigate("/delivered")}>
                          Sales History
                        </NavLink>}
                        {checkPermissionsInArray(AllPermissions.Cancelled_Order_Tab) && <NavLink
                          className={`nav-link p-0 style-class ${(activeTab == 'cancelled' || window?.location?.search === "?cancelled") ? 'active' : ''}`}
                          to="/cancelled-order"
                          data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                          data-bs-target="#navbarSupportedContent"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                          onClick={() => navigate("/cancelled-order")}>
                          Cancelled Order
                        </NavLink>}
                        {checkPermissionsInArray(AllPermissions.Sales_Return_Tab) && <NavLink className={`nav-link p-0 style-class ${(activeTab == 'salesReturn') ? 'active' : ''}`} to="/sales-return"
                          data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                          data-bs-target="#navbarSupportedContent"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                          onClick={() => navigate("/sales-return")}>
                          Sales Return
                        </NavLink>}
                        {checkPermissionsInArray(AllPermissions.Sales_Return_History_Tab) && <NavLink className={`nav-link p-0 style-class`} to="/sales-return-history"
                          data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                          data-bs-target="#navbarSupportedContent"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                          onClick={() => navigate("/sales-return-history")}>
                          Sales Return History
                        </NavLink>}
                      </div>}
                  </div>
                </li>}
              {/* {checkPermissionsInArray(AllPermissions.Transfer_Tab) && <li className="nav-item ">
                <NavLink className=" nav-link p-0 style-class position-relative" to="/transfer-request"
                  data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => navigate("/transfer-request")}>
                  Transfer
                  <Badge count={notificationCount?.other_req_count} className='translate' />
                </NavLink>
              </li>} */}
              {/* TODO make permission for transfer tabs */}
              {checkPermissionsInArray(AllPermissions.Transfer_Tab) &&
                <li className="nav-item cursor-pointer">
                  <div className="dropdown">
                    <div className={`nav-link p-0 style-class`} id="dropdownMenu"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Transfer
                    </div>
                    {(checkPermissionsInArray(AllPermissions.Transfer_Tab) || checkPermissionsInArray(AllPermissions.Transfer_Tab)) &&
                      <div className="dropdown-menu" aria-labelledby="dropdownMenu">
                        {
                          checkPermissionsInArray(AllPermissions.Transfer_Tab) &&
                          <NavLink className={`nav-link p-0 style-class`} to="/transfer-request"
                            data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => navigate("/transfer-request")}>
                            Transfer Request
                            {/* <Badge count={notificationCount?.pending_order_count} className='translate-align' /> */}
                          </NavLink>}
                        {
                          checkPermissionsInArray(AllPermissions.Transfer_Tab) &&
                          <NavLink className={`nav-link p-0 style-class`} to="/internal-transfer"
                            data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => navigate("/internal-transfer")}>
                            Internal Transfer
                          </NavLink>}
                      </div>}
                  </div>
                </li>}
              {checkPermissionsInArray(AllPermissions.Warranty_Tab) && <li className="nav-item cursor-pointer">
                <div className="dropdown">
                  <div className={`nav-link p-0 style-class`} id="dropdownMenu"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Warranty
                    {/* <Badge count={notificationCount?.pending_order_count} /> */}
                  </div>
                  {(checkPermissionsInArray(AllPermissions.New_Claim_Tab) || checkPermissionsInArray(AllPermissions.Claim_In_Process_Tab) || checkPermissionsInArray(AllPermissions.Claim_History_Tab)) && <div className="dropdown-menu" aria-labelledby="dropdownMenu">
                    {checkPermissionsInArray(AllPermissions.New_Claim_Tab) && <NavLink className={`nav-link p-0 style-class`} to="/warranty"
                      data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      onClick={() => navigate("/warranty")}>
                      New Claim
                      {/* <Badge count={notificationCount?.pending_order_count} className='translate-align' /> */}
                    </NavLink>}
                    {checkPermissionsInArray(AllPermissions.Claim_In_Process_Tab) && <NavLink className={`nav-link p-0 style-class`} to="/claimed"
                      data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      onClick={() => navigate("/claimed")}>
                      Claim In Process
                    </NavLink>}
                    {checkPermissionsInArray(AllPermissions.Claim_History_Tab) && <NavLink className={`nav-link p-0 style-class`} to="/claimed-history"
                      data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      onClick={() => navigate("/claimed-history")}>
                      Claim History
                    </NavLink>}
                  </div>}
                </div>
              </li>}
              {checkPermissionsInArray(AllPermissions.Prescription_Tab) &&
                <li className="nav-item ">
                  <NavLink className=" nav-link p-0 style-class position-relative" to="/prescription"
                    data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => navigate("/prescription")}>
                    Prescription
                    {/* <Badge count={notificationCount?.other_req_count} /> */}
                  </NavLink>
                </li>}
              {checkPermissionsInArray(AllPermissions.Refund_Tab) &&
                <li className="nav-item cursor-pointer">
                  <div className="dropdown">
                    <div className={`nav-link p-0 style-class`} id="dropdownMenu"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Refunds
                      {/* <Badge count={notificationCount?.pending_order_count} /> */}
                    </div>
                    {(checkPermissionsInArray(AllPermissions.Payment_Voucher_Tab) || checkPermissionsInArray(AllPermissions.Payment_Voucher_History_Tab)) &&
                      <div className="dropdown-menu" aria-labelledby="dropdownMenu">
                        {
                          checkPermissionsInArray(AllPermissions.Payment_Voucher_Tab) &&
                          <NavLink className={`nav-link p-0 style-class`} to="/payment-voucher"
                            data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => navigate("/payment-voucher")}>
                            Payment Voucher
                            {/* <Badge count={notificationCount?.pending_order_count} className='translate-align' /> */}
                          </NavLink>}
                        {
                          checkPermissionsInArray(AllPermissions.Payment_Voucher_History_Tab) &&
                          <NavLink className={`nav-link p-0 style-class`} to="/payment-voucher-history"
                            data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => navigate("/payment-voucher-history")}>
                            Payment Voucher History
                          </NavLink>}
                      </div>}
                  </div>
                </li>}
              {/* TODO create the permission from e-online order */}
              {checkPermissionsInArray(AllPermissions.Refund_Tab) &&
                <li className="nav-item cursor-pointer">
                  <div className="dropdown">
                    <div className={`nav-link p-0 style-class`} id="dropdownMenu"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Online Order
                      {/* <Badge count={notificationCount?.pending_order_count} /> */}
                    </div>
                    {(checkPermissionsInArray(AllPermissions.Payment_Voucher_Tab) || checkPermissionsInArray(AllPermissions.Payment_Voucher_History_Tab)) &&
                      <div className="dropdown-menu" aria-labelledby="dropdownMenu">
                        {
                          checkPermissionsInArray(AllPermissions.Payment_Voucher_Tab) &&
                          <NavLink className={`nav-link p-0 style-class`} to='/online/e-orders'
                            data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => navigate('/online/e-orders')}>
                            E-Pending Order
                            {/* <Badge count={notificationCount?.pending_order_count} className='translate-align' /> */}
                          </NavLink>}
                        {
                          checkPermissionsInArray(AllPermissions.Payment_Voucher_History_Tab) &&
                          <NavLink className={`nav-link p-0 style-class`} to='/online/in-process'
                            data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => navigate('/online/in-process')}>
                            E-In Process
                          </NavLink>}
                        {
                          checkPermissionsInArray(AllPermissions.Payment_Voucher_Tab) &&
                          <NavLink className={`nav-link p-0 style-class`} to='/online/sales-history'
                            data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => navigate('/online/sales-history')}>
                            E-Sales History
                            {/* <Badge count={notificationCount?.pending_order_count} className='translate-align' /> */}
                          </NavLink>}
                        {
                          checkPermissionsInArray(AllPermissions.Payment_Voucher_History_Tab) &&
                          <NavLink className={`nav-link p-0 style-class`} to='/online/cancelled-history'
                            data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => navigate('/online/cancelled-history')}>
                            E-Cancelled Order
                          </NavLink>}
                      </div>}
                  </div>
                </li>}
              {/* <li className='d-block d-md-none'>
                  <div className="d-flex align-items-center gap-2">
                    <Image src={User} />
                    <div className='d-block'>
                      <div className='m-auto text-muted text-capitalize'>
                        {Auth_details?.store_name}
                      </div>
                      <div className='m-auto text-muted'>
                        {Auth_details?.username}
                      </div>
                    </div>
                  </div>
                </li> */}
              <li className="d-none nav-item d-block d-md-none ms-3 cursor-pointer "
                onClick={() => logout()}>
                <TbLogout />
                <span className='p-2'
                >Logout</span>
              </li>
            </ul>
            <div
              className='navbar-collapse'
              id="navbar-collapse"
              data-bs-toggle={window.innerWidth < 825 ? "collapse" : ""}
              // data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ position: "fixed", opacity: "0.5", top: 0, left: 0, height: window.innerWidth < 825 ? "100vh" : "10vh", width: window.innerWidth < 825 ? "100vw" : "5vw", zIndex: "-1" }}>
            </div>
          </div>
        </nav >

      </div >
      <div className='empty-div'></div>
      <>
        {cartPopup &&
          <Modal className="" isOpen={cartPopup} >
            <ModalHeader className="d-flex justify-content-center border-0 position-relative" >
              <div className="mt-4 fs-30 fw-600">Warning !!</div>
              <div className="position-absolute py-1 px-2" style={{
                top: '0', right: '0', backgroundColor: '#222',
                borderRadius: '0px 4px 0px 8px', cursor: 'pointer'
              }} onClick={() => setCartPopup(false)}>
                <RxCross2 style={{ color: '#fff' }} />
              </div>
            </ModalHeader>
            <ModalBody className="rounded-10" style={{ height: 'auto' }}>
              <h3 className='text-center mt-25'>Please empty your cart before logout </h3>
            </ModalBody>
          </Modal>
        }
      </>
    </>

  )
}

export default Header