import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interface SelectedCheckboxesState {
//   [key: string]: string[];
// }

const initialState: any = {
  brand: [],
  type: [],
  polarized: [],
  frame_materials: [],
  product_shapes: [],
  rim_type: [],
  features: [],
  gender: [],
  price: [],
  sw_color_list: [],
  lens_color: [],
  bridge_size: [],
  weight: [],
  glass_size: [],
  temple_size: [],
  period_use:[],  
  pack_of_qty:[],
};

const checkboxSlice = createSlice({
  name: 'checkboxes',
  initialState,
  reducers: {
    setSelectedCheckboxes: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
    updateSelectedCheckboxes: (
      state,
      action: PayloadAction<{ category: string; checkbox: { id: string; label: string } }>
    ) => {
      const { category, checkbox } = action.payload;
      const categorySelected = state[category] || [];
      const index = categorySelected.findIndex((item:any) => item.id === checkbox.id);

      if (index >= 0) {
        state[category] = categorySelected.filter((item:any) => item.id !== checkbox.id);
      } else {
        state[category] = [...categorySelected, checkbox];
      }
    },
    removeSelectedCheckboxes: (state, action: PayloadAction<{ category: string; }>) => {
      const { category } = action.payload;
      state[category] = []
    },
  },
});

export const { setSelectedCheckboxes, updateSelectedCheckboxes,removeSelectedCheckboxes } = checkboxSlice.actions;

export default checkboxSlice.reducer;
