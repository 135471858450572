import React from "react";
import "./AvailableBadge.scss";
import { AvailableBadgeProps } from "./interface";
const Index = (props: AvailableBadgeProps) => {
    const { title, style } = props;
    return (
        <div
            className={`badge-product-sale ${style ? style : 'available'}`}
        >
            <span className="new">{`${title ? title : "Available At Store"}`}</span>
        </div>
    );
};

export default Index;
