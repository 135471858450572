import React, { useState } from 'react'
import Images from '../../../assets/Images'
import atoms from '../../atoms'
import PriceRange from '../PriceRange'
import { RiWindyFill } from 'react-icons/ri'
import { RxCross2 } from "react-icons/rx";
import constant from '../../../constants/constant'
const { FilterImage, CancelIcon } = Images
const { Image, Checkbox, Button } = atoms
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store/store';
import { removeSelectedCheckboxes, setSelectedCheckboxes, updateSelectedCheckboxes } from '../../../features/productList/filter/checkboxSlice';
import { setIsAvailableCheck, setIsPicturesCheck, setSortOrder } from '../../../features/productList/availableFilter'
import { setResetAllSearch } from '../../../features/productList/list/searchSlice'
import { setPageCount } from '../../../features/productList/pageCount/pageCount'

const Index = ({ range, handlePriceRange, handleInputPrice, setShowFilter }: any) => {
    const [glassClick, setGlassClick] = useState(false)
    const dispatch: AppDispatch = useDispatch();
    const reduxFilterList: any = useSelector((state: RootState) => state?.rootReducer?.checkboxAPI.products);
    const reduxSelectCheckboxes = useSelector((state: RootState) => state.rootReducer?.checkboxReducer);
    const reduxIsAvailableChecked = useSelector((state: RootState) => state?.rootReducer?.isAvailable);
    const reduxPriceSort = useSelector((state: RootState) => state?.rootReducer?.isAvailable)
    let hasContactLens = reduxSelectCheckboxes?.type && reduxSelectCheckboxes?.type?.some((item: any) =>
        item?.label?.toLowerCase() === 'contact lens'
    );
    const handleCheckboxClick = (id: string, category: string, label: string) => {
        dispatch(updateSelectedCheckboxes({ category, checkbox: { id, label } }));
    };
    const handleTypeCheckboxClick = (id: string, category: string, label: string) => {
        let isContactLens = reduxSelectCheckboxes?.type && reduxSelectCheckboxes?.type?.some((item: any) =>
            label?.toLowerCase() === 'contact lens'
        );
        if((!isContactLens && !hasContactLens) || (isContactLens && hasContactLens)){
            dispatch(setSelectedCheckboxes({
                ...reduxSelectCheckboxes,
                period_use: [],
                pack_of_qty: [],
            }));
        }
        dispatch(updateSelectedCheckboxes({ category, checkbox: { id, label } }));
    };

    const handleGlassClick = (id: string, category: string, label: string) => {
        if (glassClick) {
            dispatch(removeSelectedCheckboxes({ category }));
            dispatch(updateSelectedCheckboxes({ category, checkbox: { id, label } }));
            setGlassClick(false)
        } else {
            dispatch(updateSelectedCheckboxes({ category, checkbox: { id, label } }));
        }
    }
    const handlePowerClick = (id: string, category: string, label: string) => {
        if (glassClick === false) {
            dispatch(removeSelectedCheckboxes({ category }));
            dispatch(updateSelectedCheckboxes({ category, checkbox: { id, label } }));
            setGlassClick(true)
        } else {
            dispatch(updateSelectedCheckboxes({ category, checkbox: { id, label } }));
        }
    }

    const resetCheckboxes = () => {
        dispatch(setSelectedCheckboxes({
            brand: [],
            type: [],
            polarized: [],
            frame_materials: [],
            product_shapes: [],
            rim_type: [],
            features: [],
            gender: [],
            price: [],
            sw_color_list: [],
            lens_color: [],
            bridge_size: [],
            weight: [],
            glass_size: [],
            temple_size: [],
            period_use: [],
            pack_of_qty: [],
        }));
    };

    return (
        <>
            <div className='overlay'>
                <div className={` side-filter sidebar-fixed mt-3 `}>
                    <div className="d-md-none position-absolute py-1 px-2 filter-close-icon"
                        onClick={() => setShowFilter()}>
                        <RxCross2 style={{ color: '#fff' }} />
                    </div>
                    {/* <div className="position-sticky side-filter sidebar-fixed mt-3"> */}
                    <div className="d-flex justify-content-between border-bottom mt-3 mt-md-0 pb-3 filter-spacing">
                        <div className="d-flex align-items-center gap-2" >
                            <Image src={FilterImage} alt="" />
                            <h2 className='mb-0 fw-400 fs-16'>Filter</h2>
                        </div>
                        <h2 className='mb-0 fs-16 fw-400 text-decoration-underline cursor-pointer'
                            onClick={() => {
                                resetCheckboxes()
                                handlePriceRange()
                                dispatch(setIsAvailableCheck(false))
                                dispatch(setIsPicturesCheck(false))
                                dispatch(setResetAllSearch())
                            }}>Clear All</h2>
                    </div>

                    {
                        <div className="accordion" id="accordionExample" >
                            <div className=''>
                                <label
                                    className="inputs-label fs-16 fw-600 secondary-color"
                                    htmlFor={'isAvailable'}
                                >
                                    <div className="form-group d-flex align-items-center ms-1 gap-1" style={{ minHeight: '36px' }}>
                                        <input
                                            className={`form-check-input shadow-none mt-0`}
                                            type="checkbox"
                                            id={'isAvailable'}
                                            name={'isAvailable'}
                                            checked={reduxIsAvailableChecked?.isAvailableCheck}
                                            onChange={(e) => { dispatch(setIsAvailableCheck(e.target.checked)); dispatch(setPageCount(1)) }}
                                        />

                                        <span>Available at store</span>
                                    </div>
                                </label>
                                <label
                                    className="inputs-label fs-16 fw-600 secondary-color"
                                    htmlFor={'isPictures'}
                                >
                                    <div className="form-group d-flex align-items-center ms-1 gap-1" style={{ minHeight: '36px' }}>
                                        <input
                                            className={`form-check-input shadow-none mt-0`}
                                            type="checkbox"
                                            id={'isPictures'}
                                            name={'isPictures'}
                                            checked={reduxIsAvailableChecked?.isPictures}
                                            onChange={(e) => { dispatch(setIsPicturesCheck(e.target.checked)); dispatch(setPageCount(1)) }}
                                        />

                                        <span>Products with Pictures</span>
                                    </div>
                                </label>
                            </div>
                            <div className='filter-items-height'>
                                {/* <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingSort">
                                            <button
                                                className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseSort"
                                                aria-expanded="false"
                                                aria-controls="collapseSort">
                                                Sort
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseSort"
                                            className="accordion-collapse collapse border-0 fs-14 fw-400 accordion-filter-body"
                                            aria-labelledby="headingSort"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                            <div className="accordion-body px-2 py-1">
                                                <div className='d-flex gap-2 align-items-center '>
                                                    <input id="lowToHigh" type="checkbox" className='filter-style'
                                                        checked={reduxPriceSort?.sort_order===constant?.Product_List_Price_Sort.Low_To_High}
                                                        onChange={()=>dispatch(setSortOrder(constant?.Product_List_Price_Sort?.Low_To_High))} />
                                                    <label htmlFor="lowToHigh" className='mb-0 fs-14 fw-14'>Price: Low to High</label>
                                                </div>
                                            </div>
                                            <div className="accordion-body px-2 py-1">
                                                <div className='d-flex gap-2 align-items-center '>
                                                    <input id="highToLow" type="checkbox" className='filter-style'
                                                        checked={reduxPriceSort?.sort_order===constant?.Product_List_Price_Sort.High_To_Low}
                                                        onChange={()=>dispatch(setSortOrder(constant?.Product_List_Price_Sort?.High_To_Low))} />
                                                    <label htmlFor="lowToHigh" className='mb-0 fs-14 fw-14'>Price: High to Low</label>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button
                                                className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="collapseTwo">
                                                Type
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseTwo"
                                            className="accordion-collapse collapse border-0 fs-14 fw-400 accordion-filter-body"
                                            aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.type?.map((item: any) => {
                                                    return (
                                                        <div className="accordion-body px-2 py-1">
                                                            <div className='d-flex gap-2 align-items-center '>
                                                                <input id={item.id} type="checkbox" className='filter-style'
                                                                    checked={reduxSelectCheckboxes?.type?.some((selectedItem: any) => selectedItem.id === item.id)}
                                                                    onChange={() => handleTypeCheckboxClick(item.id, 'type', item.name)} />
                                                                <label htmlFor={item.id} className='mb-0 fs-14 fw-14'>{item.name}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {hasContactLens && <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingSixteen">
                                            <button className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseSixteen"
                                                aria-expanded="false"
                                                aria-controls="collapseSixteen">
                                                Pack of Qty
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseSixteen"
                                            className="accordion-collapse collapse border-0 fs-14 fw-400 accordion-filter-body"
                                            aria-labelledby="headingSixteen"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.pack_of_qty?.map((item: any) => {
                                                    return (<div className="accordion-body px-2 py-1" key={item}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={item} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.pack_of_qty?.some((selectedItem: any) => selectedItem.id === item)}
                                                                onChange={() => handleCheckboxClick(item, 'pack_of_qty', item)} />
                                                            <label htmlFor={item} className='mb-0 fs-14 fw-14'>{item}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {hasContactLens && <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingSeventeen">
                                            <button className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseSeventeen"
                                                aria-expanded="false"
                                                aria-controls="collapseSeventeen">
                                                Period Use                                            </button>
                                        </h2>
                                        <div
                                            id="collapseSeventeen"
                                            className="accordion-collapse collapse border-0 fs-14 fw-400 accordion-filter-body"
                                            aria-labelledby="headingSeventeen"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.period_use?.map((item: any) => {
                                                    return (<div className="accordion-body px-2 py-1" key={item.value}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={item.value} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.period_use?.some((selectedItem: any) => selectedItem.id === item.value)}
                                                                onChange={() => handleCheckboxClick(item.value, 'period_use', item.label)} />
                                                            <label htmlFor={item.value} className='mb-0 fs-14 fw-14'>{item.label}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingZero">
                                            <button
                                                className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseZero"
                                                aria-expanded="false"
                                                aria-controls="collapseZero">
                                                Brand
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseZero"
                                            className="accordion-collapse collapse border-0 fs-14 fw-400 accordion-filter-body"
                                            aria-labelledby="headingZero"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.brand?.map((item: any) => {
                                                    return (<div className="accordion-body px-2 py-1" key={item.id}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={item.id} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.brand?.some((selectedItem: any) => selectedItem.id === item.id)}
                                                                onChange={() => handleCheckboxClick(item.id, 'brand', item.brand_name)} />
                                                            <label htmlFor={item.id} className='mb-0 fs-14 fw-14'>{item.brand_name}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingNine">
                                            <button className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseNine"
                                                aria-expanded="false"
                                                aria-controls="collapseNine">
                                                Price
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseNine"
                                            className="accordion-collapse collapse py-2 border-0 fs-16 fw-400 accordion-filter-body"
                                            style={{ maxHeight: "30vh !important" }}
                                            aria-labelledby="headingNine"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <PriceRange range={range} onChange={handlePriceRange} handleInputPrice={handleInputPrice} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingFive">
                                            <button className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseFive"
                                                aria-expanded="false"
                                                aria-controls="collapseFive">
                                                Shape
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseFive"
                                            className="accordion-collapse collapse border-0 fs-14 fw-400 accordion-filter-body"
                                            aria-labelledby="headingFive"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.product_shapes?.map((item: any) => {
                                                    return (<div className="accordion-body px-2 py-1" key={item.id}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={item.id} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.product_shapes?.some((selectedItem: any) => selectedItem.id === item.id)}
                                                                onChange={() => handleCheckboxClick(item.id, 'product_shapes', item.name)} />
                                                            <label htmlFor={item.id} className='mb-0 fs-14 fw-14'>{item.name}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseSix"
                                                aria-expanded="false"
                                                aria-controls="collapseSix">
                                                Rim Type
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseSix"
                                            className="accordion-collapse collapse border-0 fs-14 fw-400 accordion-filter-body"
                                            aria-labelledby="headingSix"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.rim_type?.map((item: any) => {
                                                    return (<div className="accordion-body px-2 py-1" key={item.value}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={`rim_${item.value}`} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.rim_type?.some((selectedItem: any) => selectedItem.id === item.value)}
                                                                onChange={() => handleCheckboxClick(item.value, 'rim_type', item.label)} />
                                                            <label htmlFor={`rim_${item.value}`} className='mb-0 fs-14 fw-14'>{item.label}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingTen">
                                            <button className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseTen"
                                                aria-expanded="false"
                                                aria-controls="collapseTen">
                                                Color
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseTen"
                                            className="accordion-collapse collapse border-0 fs-16 fw-400 accordion-filter-body"
                                            aria-labelledby="headingTen"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.sw_color_list?.map((item: any) => {
                                                    return (<div className="accordion-body px-2 py-1" key={item.id}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={`sw_color_list_${item.id}`} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.sw_color_list?.some((selectedItem: any) => selectedItem.id === item.id)}
                                                                onChange={() => handleCheckboxClick(item.id, 'sw_color_list', item.color_name)} />
                                                            <label htmlFor={`sw_color_list_${item.id}`} className='mb-0 fs-14 fw-14'>{item.color_name}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingEleven">
                                            <button className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseEleven"
                                                aria-expanded="false"
                                                aria-controls="collapseEleven">
                                                Lens Color
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseEleven"
                                            className="accordion-collapse collapse border-0 fs-16 fw-400 accordion-filter-body"
                                            aria-labelledby="headingEleven"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.lens_color?.map((item: any) => {
                                                    return (<div className="accordion-body px-2 py-1" key={item.id}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={`lens_color_${item.id}`} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.lens_color?.some((selectedItem: any) => selectedItem.id === item.id)}
                                                                onChange={() => handleCheckboxClick(item.id, 'lens_color', item.color_name)} />
                                                            <label htmlFor={`lens_color_${item.id}`} className='mb-0 fs-14 fw-14'>{item.color_name}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button className="accordion-button collapsed  px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseFour"
                                                aria-expanded="false"
                                                aria-controls="collapseFour">
                                                Polarized
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseFour"
                                            className="accordion-collapse collapse border-0 fs-14 fw-400 accordion-filter-body"
                                            aria-labelledby="headingFour"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.polarized?.map((item: any) => {
                                                    return (<div className="accordion-body px-2 py-1" key={item.value}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={item.value} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.polarized?.some((selectedItem: any) => selectedItem.id === item.value)}
                                                                onChange={() => handleCheckboxClick(item.value, 'polarized', item.label)} />
                                                            <label htmlFor={item.value} className='mb-0 fs-14 fw-14'>{item.label}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingFourteen">
                                            <button className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseFourteen"
                                                aria-expanded="false"
                                                aria-controls="collapseFourteen">
                                                Size
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseFourteen"
                                            className="accordion-collapse collapse accordion-filter-body border-0 fs-16 fw-400 "
                                            aria-labelledby="headingFourteen"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.glass_size?.map((item: any, index: number) => {
                                                    return (<div className="accordion-body px-2 py-1" key={item.id}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={`glass_size_${index}`} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.glass_size?.some((selectedItem: any) => selectedItem.id === item)}
                                                                onChange={() => handleGlassClick(item, 'glass_size', item)} />
                                                            <label htmlFor={`glass_size_${index}`} className='mb-0 fs-14 fw-14'>{item}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingPower">
                                            <button className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapsePower"
                                                aria-expanded="false"
                                                aria-controls="collapsePower">
                                                Power
                                            </button>
                                        </h2>

                                        <div
                                            id="collapsePower"
                                            className="accordion-collapse collapse accordion-filter-body border-0 fs-16 fw-400 "
                                            aria-labelledby="headingFourteen"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.powers?.map((item: any, index: number) => {
                                                    return (<div className="accordion-body px-2 py-1" key={item.id}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={`glass_size_${index}`} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.glass_size?.some((selectedItem: any) => selectedItem.id === item)}
                                                                onChange={() => handlePowerClick(item, 'glass_size', item)} />
                                                            <label htmlFor={`glass_size_${index}`} className='mb-0 fs-14 fw-14'>{item}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed px-3 py-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Material
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse border-0 fs-14 fw-400 accordion-filter-body" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.frame_materials?.map((item: any) => {
                                                    return (<div className="accordion-body px-2 py-1" key={item.id}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={item.id} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.frame_materials?.some((selectedItem: any) => selectedItem.id === item.id)}
                                                                onChange={() => handleCheckboxClick(item.id, 'frame_materials', item.name)} />
                                                            <label htmlFor={item.id} className='mb-0 fs-14 fw-14'>{item.name}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingEight">
                                            <button className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseEight"
                                                aria-expanded="false"
                                                aria-controls="collapseEight">
                                                Gender
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseEight"
                                            className="accordion-collapse collapse border-0 fs-16 fw-400 accordion-filter-body"
                                            aria-labelledby="headingEight"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.gender?.map((item: any) => {
                                                    return (<div className="accordion-body px-2 py-1" key={item.value}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={`gender_${item.value}`} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.gender?.some((selectedItem: any) => selectedItem.id === item.value)}
                                                                onChange={() => handleCheckboxClick(item.value, 'gender', item.label)} />
                                                            <label htmlFor={`gender_${item.value}`} className='mb-0 fs-14 fw-14'>{item.label}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingThirteen">
                                            <button className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseThirteen"
                                                aria-expanded="false"
                                                aria-controls="collapseThirteen">
                                                Weight
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseThirteen"
                                            className="accordion-collapse collapse border-0 fs-16 fw-400 accordion-filter-body"
                                            aria-labelledby="headingThirteen"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.weight?.map((item: any) => {
                                                    return (<div className="accordion-body px-2 py-1" key={item.value}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={`weight_${item.value}`} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.weight?.some((selectedItem: any) => selectedItem.id === item.value)}
                                                                onChange={() => handleCheckboxClick(item.value, 'weight', item.label)} />
                                                            <label htmlFor={`weight_${item.value}`} className='mb-0 fs-14 fw-14'>{item.label}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingSeven">
                                            <button className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="collapseSeven">
                                                Features
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseSeven"
                                            className="accordion-collapse collapse border-0 fs-16 fw-400 accordion-filter-body"
                                            aria-labelledby="headingSeven"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.features?.map((item: any) => {
                                                    return (<div className="accordion-body px-2 py-1" key={item.value}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={`features_${item.value}`} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.features?.some((selectedItem: any) => selectedItem.id === item.value)}
                                                                onChange={() => handleCheckboxClick(item.value, 'features', item.label)} />
                                                            <label htmlFor={`features_${item.value}`} className='mb-0 fs-14 fw-14'>{item.label}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingTwelve">
                                            <button className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwelve"
                                                aria-expanded="false"
                                                aria-controls="collapseTwelve">
                                                Bridge Size
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseTwelve"
                                            className="accordion-collapse collapse border-0 fs-16 fw-400 accordion-filter-body"
                                            aria-labelledby="headingTwelve"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.bridge_size?.map((item: any, index: number) => {
                                                    return (<div className="accordion-body px-2 py-1" key={index}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={`bridge_size_${index}`} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.bridge_size?.some((selectedItem: any) => selectedItem.id === item)}
                                                                onChange={() => handleCheckboxClick(item, 'bridge_size', item)} />
                                                            <label htmlFor={`bridge_size_${index}`} className='mb-0 fs-14 fw-14'>{item}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <div className="accordion-item mt-1">
                                        <h2 className="accordion-header" id="headingFifteen">
                                            <button className="accordion-button collapsed px-3 py-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseFifteen"
                                                aria-expanded="false"
                                                aria-controls="collapseFifteen">
                                                Temple Size
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseFifteen"
                                            className="accordion-collapse collapse border-0 fs-16 fw-400 accordion-filter-body"
                                            aria-labelledby="headingFifteen"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body py-2">
                                                {reduxFilterList && reduxFilterList?.temple_size?.map((item: any, index: number) => {
                                                    return (<div className="accordion-body px-2 py-1" key={index}>
                                                        <div className='d-flex gap-2 align-items-center '>
                                                            <input id={`temple_size_${index}`} type="checkbox" className='filter-style'
                                                                checked={reduxSelectCheckboxes?.temple_size?.some((selectedItem: any) => selectedItem.id === item)}
                                                                onChange={() => handleCheckboxClick(item, 'temple_size', item)} />
                                                            <label htmlFor={`temple_size_${index}`} className='mb-0 fs-14 fw-14'>{item}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-md-none filter-empty-div'></div>
                            </div>

                        </div>}
                </div>
                {/* {window.innerWidth < 768 && <div className='d-flex gap-3 p-3' style={{ position: "absolute", bottom: "16px", zIndex: "1024", left: 0, width: "100vw", backgroundColor: "white", height:"107px" }}>
                    <Button
                        className='cancel-btn'
                        style={{ minWidth: "120px" }}
                        onClick={() => {
                            setSelectedCheckboxes({})
                            handlePriceRange()
                            setIsAvailableChecked(false)
                        }}>Clear All</Button>
                    <Button className='outline-button-filter' style={{ minWidth: "230px", maxHeight:"55px" }} onClick={() => setShowFilter()}>Close</Button>
                </div>} */}
            </div>
        </>
    )
}

export default Index