import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { transferRequest } = Dictionary

const transferRequestService={
    list: async (paramsObj: any) => {
        return axios.get(BASE_URL + transferRequest.list() ,{ params: paramsObj })
    },

    // for internal transfer request
    storeList: async () => {
        return axios.get(BASE_URL + transferRequest.storeList() )
    },
    submit:async(payload:{})=>{
        return axios.post(BASE_URL + transferRequest.submit(), payload)
    }
}

export default transferRequestService