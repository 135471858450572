import React from 'react'
import atoms from '../../../atoms'
import moment from 'moment'
import { convertToCommaFormat } from '../../../../constants/utils'
import constant from '../../../../constants/constant'

import ThreeDots from '../../../../assets/Images/threeDots.svg'

import { RiDeleteBin6Line } from 'react-icons/ri'
import { AiOutlineEye } from 'react-icons/ai'
import { EPendingOrderData, EPendingOrderProps } from './interface'
import { RxUpdate } from 'react-icons/rx'
import { MdOutlineFileDownload } from 'react-icons/md'
const { Search, Table, Button, Pagination, Image } = atoms

const Index = ({ receiptData, setSearch, setLimitPerPage, setCurrentPage, currentPage, limit, handleView, handleCancel,handleApproveOrder }: EPendingOrderProps) => {

    const columns = [
        { name: 'Date', selector: (row: EPendingOrderData) => moment(row?.createdAt).format('DD/MM/YYYY'), width: '100px' },
        { name: 'Online  Order No.', selector: (row: EPendingOrderData) => row?.order_id_display, width: '150px' },
        { name: 'Customer Name', selector: (row: EPendingOrderData) => row?.customer?.name || '-', width: '150px' },
        { name: 'Mobile No.', selector: (row: EPendingOrderData) => row?.customer?.mobile, width: '150px' },
        { name: 'Total Amount', selector: (row: EPendingOrderData) => `₹${convertToCommaFormat(row?.total_amount)}` || '-', width: '150px' },
        {
            name: 'Total Items', selector: (row: EPendingOrderData) => <>
                <div>
                    <p className='mb-1 ' >Total Order : {row.total_order_items}</p>
                    <p className='mb-1 '>Delivered : {row.total_delivered_order_items}</p>
                    <p className='mb-1 '>Pending : {row.total_pending_order_items}</p>

                </div>
            </>, width: '140px'
        },
        { name: 'Delivery', selector: (row: any) => row?.order_status || '-', width: '150px', text:"center" },
        { name: 'Action', selector: (row: any) => row?.action, center: true },
    ];

    return (
        <>
            <div className='row mt-md-0 mt-3'>
                <div className='col-md-6'>
                    <Search className='search search-style w-100' searchCallBack={(e: string) => { setSearch(e) }} />
                </div>
                <div>
                    <Table
                        columns={columns}
                        data={receiptData?.data?.map((item: any) => {
                            return ({
                                ...item,
                                order_status: item?.store_pick_up  ? <div className="background-light-yellow border-product-radius custom-badge">
                                    <p className="color-yellow m-1 p-1">{'Store pickup'}</p>
                                    </div> : <div className="background-light-green border-product-radius custom-badge">
                                    <p className="color-green m-1 p-1">{'Home pickup'}</p>
                                    </div>
                                ,
                                action: <>
                                    {<div className='table-data-width'>
                                        <button
                                            className="btn dropdown-dots shadow-none action-button"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <Image src={ThreeDots} />
                                        </button>
                                        <ul
                                            className="dropdown-menu dropdown-menu-end dropdown-style zindex-tooltip"
                                            aria-labelledby="dropdownMenuButton1"
                                            style={{ width: "210px" }}
                                        >



                                            {/* Update Option */}
                                            <li>
                                                <button className="dropdown-item dropdown-item-size"
                                                    onClick={() => handleView(item?.id)}>
                                                    <div className='d-flex align-items-center'>
                                                        <MdOutlineFileDownload />
                                                        <span className='ms-2 mb-0'>Download</span>
                                                    </div>
                                                </button>
                                            </li>

                                            <hr className="my-1"></hr>
                                            {/* Download Option */}

                                            <li>
                                                <button className="dropdown-item dropdown-item-size"
                                                    onClick={() => handleApproveOrder(item?.id)}>
                                                    <div className='d-flex align-items-center'>
                                                        <RxUpdate />
                                                        <span className='ms-2 mb-0'>Approve Order</span>
                                                    </div>
                                                </button>
                                            </li>

                                            <hr className="my-1"></hr>
                                            {/* Download Option */}

                                            <li>
                                                <button className="dropdown-item dropdown-item-size"
                                                    onClick={() => handleCancel(item?.id)}>
                                                    <div className='d-flex align-items-center text-danger'>
                                                        <RiDeleteBin6Line />
                                                        <span className='ms-2 mb-0'>Cancel</span>
                                                    </div>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>}
                                </>
                            })
                        })}
                    />

                </div>
                <div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={receiptData?.totalPages}
                        onChangePage={(page: number) => setCurrentPage(page)}
                        setLimit={(e: number) => setLimitPerPage(e)}
                        limit={limit}
                    />
                </div>
            </div>
        </>
    )
}

export default Index