import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shouldRefetch:false
};

const shouldRefetchListSlice = createSlice({
  name: "shouldRefetch",
  initialState,
  reducers: {
    setShouldRefetch: (state, action) => {
      state.shouldRefetch = action.payload;
    },
  },
});

export const { setShouldRefetch } = shouldRefetchListSlice.actions;
export default shouldRefetchListSlice.reducer;
